export default {
  blogerName: 'DERZKIY',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/KtoPoZini',
    },
    {
      name: 'telegram',
      url: 'https://t.me/era_berani_1',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Epoxa_Derzkix',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@user-xe4be2wg2i',
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs01.com/cae49acde',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c9fbe12ee',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c11edd601',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c1005d46e',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c5cae7d34',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c07e44625',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c63f3b98a',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/ce42da9ef',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-azhqmrpiks.com/cc4edb0e9',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c9ff94dfd',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c4405d7af',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>DERZKIY</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'DERZKIY',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
